import LinkWrapper from 'UI/components/LinkWrapper';
import Svg from 'UI/components/Svg';
import ClutchWidget from 'UI/components/ClutchWidget';
import styles from './styles.module.scss';

const FooterCenter = () => (
  <div className={styles['footer-body']}>
    <div className={styles['block-1']}>
      <LinkWrapper
        path="/"
        className={styles.logo}
      >
        <Svg type="whiteLogo" />
        <Svg type="whiteYellowText" />
      </LinkWrapper>

      <p className={styles['white-text']}>
        Software innovation powerhouse born to take your business to the top!
      </p>

      <ClutchWidget />
    </div>

    <div className={styles['block-2']}>
      <div>
        <h3 className={styles['grey-text']}>
          Let’s Talk
        </h3>

        <div className={styles['block-2__content']}>
          <LinkWrapper
            path="mailto:hi@yellow.systems"
            className={styles['large-text']}
            target="_blank"
          >
            hi@yellow.systems
          </LinkWrapper>

          <p className={styles['large-text']}>
            +1 (415) 470-2865
          </p>

          <LinkWrapper
            className={styles['link-text']}
            path="https://calendly.com/yellow-systems-crew/30min"
            target="_blank"
          >
            Book a call
          </LinkWrapper>
        </div>
      </div>

      <div>
        <h3 className={styles['grey-text']}>
          Address
        </h3>

        <p className={styles['large-text']}>
          🇺🇸 44 Tehama St, San Francisco, CA 94105, United States
        </p>
      </div>
    </div>
  </div>
);

export default FooterCenter;
