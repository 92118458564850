import SubscribeForm from 'UI/components/SubscribeForm';
import styles from './styles.module.scss';

const FooterHeader = () => (
  <div className={styles['footer-header']}>
    <div className={styles['text-block']}>
      <h3 className={styles['heading-text']}>
        Sign up for our newsletter!
      </h3>

      <p className={styles['normal-text']}>
        No spam, only valuable content!
      </p>
    </div>

    <SubscribeForm />
  </div>
);

export default FooterHeader;
