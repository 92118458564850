import FooterHeader from './FooterHeader';
import FooterBody from './FooterBody';
import FooterFoot from './FooterFoot';
import CircleButton from './CircleButton';
import styles from './styles.module.scss';

const DefaultFooter = () => (
  <footer className={styles.footerContainer}>
    <CircleButton />
    <FooterHeader />
    <FooterBody />
    <FooterFoot />
  </footer>
);

export default DefaultFooter;
