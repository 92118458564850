import { useEffect, useState } from 'react';

const ClutchWidget = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.clutch.co/static/js/widget.js';
    script.async = true;
    script.onload = () => {
      setIsScriptLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      if (window.CLUTCHCO) {
        window.CLUTCHCO.Init();
      }
    }
  }, [isScriptLoaded]);

  return (
    <div
      className="clutch-widget"
      data-url="https://widget.clutch.co"
      data-widget-type="1"
      data-height="40"
      data-nofollow="true"
      data-expandifr="true"
      data-darkbg="darkbg"
      data-clutchcompany-id="381822"
    />
  );
};

export default ClutchWidget;
