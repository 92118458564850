import { useRouter } from 'next/router';
import Svg from 'UI/components/Svg';
import LinkWrapper from 'UI/components/LinkWrapper';
import { SOCIAL_MEDIA } from 'utils/constants/contacts';
import styles from './styles.module.scss';

const FooterFoot = () => {
  const { pathname } = useRouter();

  return (
    <div className={styles['footer-foot']}>
      <div className={styles['social-links']}>
        {Object.values(SOCIAL_MEDIA).map(({
          iconLight: icon,
          title,
          link,
        }) => link && (
          <LinkWrapper
            className={styles['social-link']}
            key={`networks/${link}`}
            path={link}
            googleAnalyticProps={{
              action: 'Click',
              category: 'Social',
              label: pathname,
              data: title,
            }}
            isSocialLink
          >
            <Svg
              type={icon}
              className={styles.icon}
            />
          </LinkWrapper>
        ))}
      </div>
      <div className={styles.bottom}>
        <p>© All right reserved. Yellow 2024</p>
        <div className={styles['privacy-policy']}>
          <LinkWrapper
            path="/privacy-policy"
            className={styles.link}
          >
            Privacy Policy
          </LinkWrapper>
          <LinkWrapper
            path="/terms-and-conditions"
            className={styles.link}
          >
            Terms & Privacy
          </LinkWrapper>
        </div>
      </div>
    </div>
  );
};

export default FooterFoot;
