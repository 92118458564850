import Svg from 'UI/components/Svg';
import styles from './styles.module.scss';

const CircleButton = () => {
  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      type="button"
      className={styles.button}
      onClick={onClick}
    >
      <Svg type="arrowNarrowUp" />
    </button>
  );
};

export default CircleButton;
